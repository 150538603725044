// extracted by mini-css-extract-plugin
export var aboutLayerBg = "style-module--aboutLayerBg--X2o8s";
export var backToTop = "style-module--back-to-top--eC1N3";
export var contactLayerBg = "style-module--contactLayerBg--8bEEG";
export var container = "style-module--container--hH512";
export var footer = "style-module--footer--LDpDM";
export var header = "style-module--header--ThjAY";
export var headerHeight = "style-module--headerHeight--pTlIy";
export var introdueLayerBg = "style-module--introdueLayerBg--5L+z+";
export var layerPadding = "style-module--layerPadding--LGXUs";
export var portfolioLayerBg = "style-module--portfolioLayerBg--r86Xi";
export var qualityLayerBg = "style-module--qualityLayerBg--1RPXL";
export var sectionLableBlock = "style-module--sectionLableBlock--NU6VH";
export var serviceLayerBg = "style-module--serviceLayerBg--Dsc8z";
export var skillLayerBg = "style-module--skillLayerBg--5pHs5";